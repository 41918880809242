import { hasPermission } from '@/services/permissions/Permissions';

export type FloortrakPermission =
    | 'Dock Receipt'
    | 'Receipt Disposition and Closeout'
    | 'Load Complete'
    | 'Record Production Parts Found'
    | 'Unit Loads'
    | 'Create New Shipping Transaction'
    | 'Pick and Ship Existing'
    | 'Warehouse Movements'
    | 'FT Record Count'
    | 'FT Adjust Count'
    | 'Tag Maintenance'
    | 'Record Direct Labor'
    | 'Repair Items'
    | 'Processed Put Away'
    | 'Warehouse Put Away';

const FLOORTRAK_PERMISSION = {
    canAccessModule(): boolean {
        return (
            hasPermission('Dock Receipt')
            || hasPermission('Receipt Disposition and Closeout')
            || hasPermission('Load Complete')
            || hasPermission('Record Production Parts Found')
            || hasPermission('Unit Loads')
            || hasPermission('Create New Shipping Transaction')
            || hasPermission('Pick and Ship Existing')
            || hasPermission('Warehouse Movements')
            || hasPermission('FT Record Count')
            || hasPermission('Tag Maintenance')
            || hasPermission('Record Direct Labor')
            || hasPermission('Repair Items')
            || hasPermission('Processed Put Away')
            || hasPermission('Warehouse Put Away')
        );
    },
    canDockReceive(): boolean {
        return hasPermission('Dock Receipt');
    },
    canPickAndShipExisting(): boolean {
        return hasPermission('Pick and Ship Existing');
    },
    canCloseOutReceipt(): boolean {
        return hasPermission('Receipt Disposition and Closeout');
    },
    canLoadComplete(): boolean {
        return hasPermission('Load Complete');
    },
    canShipNew(): boolean {
        return hasPermission('Create New Shipping Transaction');
    },
    canMoveInventory(): boolean {
        return hasPermission('Warehouse Movements');
    },
    canRecordCount(): boolean {
        return hasPermission('FT Record Count');
    },
    canAdjustCount(): boolean {
        return hasPermission('FT Adjust Count');
    },
    canManageUnitLoads(): boolean {
        return hasPermission('Unit Loads');
    },
    canMaintainTags(): boolean {
        return hasPermission('Tag Maintenance');
    },
    canRepairItems(): boolean {
        return hasPermission('Repair Items');
    },
    canProcessPutAway(): boolean {
        return hasPermission('Processed Put Away');
    },
    canWarehousePutAway(): boolean {
        return hasPermission('Warehouse Put Away');
    },
};

export default FLOORTRAK_PERMISSION;
